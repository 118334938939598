
import cmsComponent from 'mixins/cmsComponent';
import richtext from '~/utils/richtext';
import GSignupForm from '../goose/g-signup-form';
import GStars from '../goose/g-stars';

export default {
  name: 'widget-signup-form',
  components: {
    GSignupForm,
    GStars,
  },
  mixins: [cmsComponent],
  data() {
    return {
      form: {
        ctaText: 'Try Deputy for free',
        formId: 'widget-signup-form',
        showSocialButton: false,
        inline: false,
      },
      headline: '',
      subline: '',
      showCapterra: false,
      alignText: 'widget-text-center',
      isDesktop: true,
    };
  },
  computed: {
    capterraStars() {
      return this.$store.state.stats.capterra_score;
    },
    capterraReviews() {
      return this.$store.state.stats.capterra_total_reviews;
    },
  },
  beforeMount() {
    if (typeof window !== 'undefined') {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      // headline
      urlParams.has('headline')
        ? (this.headline = urlParams.get('headline'))
        : richtext(this.blok.heading);
      // subline
      urlParams.has('subline')
        ? (this.subline = urlParams.get('subline'))
        : richtext(this.blok.subline);
      // form ID
      urlParams.has('id')
        ? (this.form.formId = urlParams.get('id'))
        : this.blok.form_id;
      // CTA Text
      urlParams.has('cta')
        ? (this.form.ctaText = urlParams.get('cta'))
        : this.blok.cta_text;
      // CTA Text
      urlParams.has('social')
        ? (this.form.showSocialButton = true)
        : this.blok.show_social_button;
      // Inline Form
      urlParams.has('inline') ? (this.form.inline = true) : this.blok.inline;
      // Show Capterra
      urlParams.has('reviews')
        ? (this.showCapterra = true)
        : this.blok.show_capterra_rating;
      // Text Alignment
      urlParams.has('text')
        ? (this.alignText = `widget-text-${urlParams.get('text')}`)
        : 'widget-text-center';

      // get type of device
      this.getDevice();
    }
  },
  methods: {
    richtext,
    getDevice() {
      // we need to do this here as the media query will just apply to the inside frame
      // and not the parent window
      this.isDesktop =
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
    },
  },
};
